import React, { useState, useEffect } from "react";
import {
  fetchVenues,
  fetchSponsors,
  fetchSports,
  uploadEventImage,
  downloadFile,
  updateEvent,
} from "../api/api";
import CropperEventImage from "../components/CropperEventImage";
import ToastNotification from "../components/ToastNotification";
import Loader from "../components/Loader";
import { FaTimes } from "react-icons/fa";

const EditEventForm = ({ event, onCancel }) => {
  const [formData, setFormData] = useState({
    EventTitle: event?.EventTitle || "",
    EventDescription: event?.EventDescription || "",
    EventType: event?.EventType || "League",
    EventLevel: event?.EventLevel || ["Novice"],
    EventImageFileId: event?.EventImageFileId || [],
    EventImages: event?.EventImages || [],
    EventTags: event?.EventTags || [],
    SportId: event?.sport.SportId || "",
    TeamSize: event?.TeamSize || 0,
    IsPartnerAllowed: event?.IsPartnerAllowed || true,
    TotalTeams: event?.TotalTeams || 0,
    PriceCurrency: event?.PriceCurrency || "INR",
    Price: event?.Price || 0,
    PriceCredits: event?.PriceCredits || 0,
    isPaid: event?.isPaid || true,
    FAQ: event?.FAQ || [{ question: "", answer: "" }],
    Venue: event?.venue || {
      VenueId: event?.venue?.VenueId || "",
      VenueName: event?.venue?.VenueName || "",
      VenueAddress: {
        country: event?.venue?.VenueAddress?.country || "",
        state: event?.venue?.VenueAddress?.state || "",
        city: event?.venue?.VenueAddress?.city || "",
        pincode: event?.venue?.VenueAddress?.pincode || "",
        landmark: event?.venue?.VenueAddress?.landmark || "",
      },
      VenueRegionId:event?.venue?.RegionId||"",
      MapLink: event?.venue?.MapLink || "",
      VenueDescription: event?.venue?.VenueDescription || "",
    },
    EventSchedule: event?.event_schedule || {
      StartDate:
        event?.event_schedule?.StartDate?.slice(0, 10) ||
        new Date().toISOString(),
      StartTimeStamp:
        event?.event_schedule?.StartTimeStamp?.slice(11, 16) ||
        new Date().toISOString(),
      EndDate:
        event?.event_schedule?.EndDate?.slice(0, 10) ||
        new Date().toISOString(),
      EndTimeStamp:
        event?.event_schedule?.EndTimeStamp?.slice(11, 16) ||
        new Date().toISOString(),
      RegistrationStartDate:
        event?.event_schedule?.RegistrationStartDate?.slice(0, 10) ||
        new Date().toISOString(),
      RegistrationStartTimeStamp:
        event?.event_schedule?.RegistrationStartTimeStamp?.slice(11, 16) ||
        new Date().toISOString(),
      RegistrationEndDate:
        event?.event_schedule?.RegistrationEndDate?.slice(0, 10) ||
        new Date().toISOString(),
      RegistrationEndTimeStamp:
        event?.event_schedule?.RegistrationEndTimeStamp?.slice(11, 16) ||
        new Date().toISOString(),
      IsRecurring: event?.event_schedule?.IsRecurring || false,
      RecurrenceRule: event?.event_schedule?.RecurrenceRule || "",
      RecurrenceInterval: event?.event_schedule?.RecurrenceInterval || 0,
      RecurrenceEndDate:
        event?.event_schedule?.RecurrenceEndDate?.slice(0, 10) || "",
      RecurrenceEndTime:
        event?.event_schedule?.RecurrenceEndDate?.slice(11, 16) || "",
      RecurrenceDaysOfWeek: event?.event_schedule?.RecurrenceDaysOfWeek || [],
    },
    SponsorIds: event?.sponsors?.map((sponsor) => sponsor.SponsorId) || [],
    isActive: true,
  });

  const [venues, setVenues] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [sports, setSports] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   console.log("Initial formData:", formData);
  // }, [formData]);
  // Fetch Venues, Sponsors, and Sports
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedVenues = await fetchVenues();
        const fetchedSponsors = await fetchSponsors();
        const fetchedSports = await fetchSports();

        setVenues(fetchedVenues);
        setSponsors(fetchedSponsors);
        setSports(fetchedSports);
        // Set VenueRegionId if it's missing in the event details
        if (!formData.Venue.VenueRegionId && formData.Venue.VenueName) {
          const matchedVenue = fetchedVenues.find(
            (venue) => venue.VenueId === formData.Venue.VenueId
          );

          if (matchedVenue) {
            setFormData((prevData) => ({
              ...prevData,
              Venue: {
                ...prevData.Venue,
                VenueRegionId: matchedVenue.VenueRegionId,
                VenueId: matchedVenue.VenueId,
              },
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching venues:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle sponsor change
  const handleSponsorChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      SponsorIds: checked
        ? [...prevData.SponsorIds, value]
        : prevData.SponsorIds.filter((id) => id !== value),
    }));
  };

  const handleVenueChange = (e) => {
    const venueId = e.target.value;
    const selectedVenue = venues.find((venue) => venue.VenueId === venueId);

    if (selectedVenue) {
      setFormData((prevData) => ({
        ...prevData,
        Venue: {
          VenueId: selectedVenue.VenueId,
          VenueName: selectedVenue.VenueName,
          VenueAddress: selectedVenue.VenueAddress,
          VenueRegionId: selectedVenue.RegionId || "",
          MapLink: selectedVenue.MapLink || "",
          VenueDescription: selectedVenue.VenueDescription || "",
        },
      }));
    }
  };

  const handleFAQChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedFAQs = [...prevData.FAQ];
      updatedFAQs[index] = { ...updatedFAQs[index], [name]: value };
      return { ...prevData, FAQ: updatedFAQs };
    });
  };

  const addFAQ = () => {
    setFormData((prevData) => ({
      ...prevData,
      FAQ: [...prevData.FAQ, { question: "", answer: "" }],
    }));
  };

  //Handle Image upload with cropper
  const handleImageCrop = async (blob) => {
    const formData = new FormData();
    formData.append("data", blob);

    try {
      const accessToken = localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth")).token
        : null;

      if (!accessToken) {
        console.error("Access token is missing. Please log in.");
        return;
      }

      const response = await uploadEventImage(formData, accessToken);

      if (response && response.FileId) {
        const preview = await downloadFile(response.FileId);

        if (!preview) {
          console.error("Preview URL could not be generated.");
          return;
        }

        setFormData((prevData) => ({
          ...prevData,
          EventImages: [
            ...prevData.EventImages,
            { FileId: response.FileId, Tags: [], preview },
          ],
          EventImageFileId: [...prevData.EventImageFileId, response.FileId],
        }));
      } else {
        console.error("Image upload failed:", response);
      }
    } catch (error) {
      console.error("Error in image upload or preview generation:", error);
    } finally {
      setShowCropper(false);
    }
  };

  const handleEventDateChange = (e, dateField, timeField) => {
    const { value } = e.target; // e.g., "2024-11-30"

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [dateField]: value, // Retain the date input value
        [timeField]:
          prevData.EventSchedule[timeField] || `${value}T00:00:00.000Z`, // Retain the previous time if available
      },
    }));
  };

  const handleEventTimeChange = (e, timeField, dateField) => {
    const { value } = e.target; // e.g., "15:00"
    const date = formData.EventSchedule[dateField]?.slice(0, 10) || ""; // Retain the previous date if available

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [timeField]: `${date}T${value}:00.000Z`, // Combine previous date with new time
      },
    }));
  };

  // Handle Registration Date Change
  const handleRegistrationDateChange = (e, dateField, timeField) => {
    const { value } = e.target; // e.g., "2024-11-30"

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [dateField]: value, // Retain the date input value
        [timeField]:
          prevData.EventSchedule[timeField] || `${value}T00:00:00.000Z`, // Retain the previous time if available
      },
    }));
  };

  // Handle Registration Start Time Change
  const handleRegistrationTimeChange = (e, timeField, dateField) => {
    const { value } = e.target; // e.g., "15:00"
    const date = formData.EventSchedule[dateField]?.slice(0, 10) || ""; // Retain the previous date if available

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [timeField]: `${date}T${value}:00.000Z`, // Combine previous date with new time
      },
    }));
  };

  const formatDateTime = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const formattedDate = date.toISOString().slice(0, 10);
    const formattedTime = date.toISOString().slice(11, 16);
    return { formattedDate, formattedTime };
  };

  // Toggle IsRecurring
  const handleIsRecurringChange = (e) => {
    const isRecurring = e.target.checked;

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        IsRecurring: isRecurring,
        ...(isRecurring
          ? {} // If IsRecurring is true, retain current values
          : {
              RecurrenceRule: prevData.EventSchedule.RecurrenceRule || "",
              RecurrenceInterval:
                prevData.EventSchedule.RecurrenceInterval || 0,
              RecurrenceEndDate: prevData.EventSchedule.RecurrenceEndDate || "",
              RecurrenceDaysOfWeek:
                prevData.EventSchedule.RecurrenceDaysOfWeek || [],
            }), // Retain the previous recurrence-related fields if available
      },
    }));
  };

  // Handle Recurrence Rule Change
  const handleRecurrenceRuleChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceRule: value, // Update RecurrenceRule in state
      },
    }));
  };

  // Handle Recurrence Interval Change
  const handleRecurrenceIntervalChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceInterval: Number(e.target.value),
      },
    }));
  };

  // Handle Recurrence End Date Change
  const handleRecurrenceEndDateChange = (e) => {
    const dateValue = e.target.value; // e.g., "2024-12-25"
    const currentTime = formData.EventSchedule.RecurrenceEndDate
      ? new Date(formData.EventSchedule.RecurrenceEndDate)
          .toLocaleTimeString("en-US", { hour12: false })
          .slice(0, 5)
      : "00:00";

    const [hours, minutes] = currentTime.split(":").map(Number);
    const newDateTime = new Date(dateValue);
    newDateTime.setHours(hours, minutes, 0, 0);

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        RecurrenceEndDate: newDateTime.toISOString(),
      },
    }));
  };

  // Handle Recurrence End Time Change
  const handleRecurrenceEndTimeChange = (e) => {
    const timeValue = e.target.value; // e.g., "15:30"
    const currentDate = formData.EventSchedule.RecurrenceEndDate
      ? new Date(formData.EventSchedule.RecurrenceEndDate.slice(0, 10)) // Use only date part
      : new Date();

    const [hours, minutes] = timeValue.split(":").map(Number);
    currentDate.setHours(hours, minutes, 0, 0);

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        RecurrenceEndDate: currentDate.toISOString(),
      },
    }));
  };

  // Handle Days of the Week Checkbox Change
  const handleDaysOfWeekChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceDaysOfWeek: checked
          ? [...prev.EventSchedule.RecurrenceDaysOfWeek, value]
          : prev.EventSchedule.RecurrenceDaysOfWeek.filter((d) => d !== value),
      },
    }));
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...formData,
      Venue: {
        VenueId: formData.Venue.VenueId,
        VenueName: formData.Venue.VenueName,
        VenueAddress: formData.Venue.VenueAddress,
        MapLink: formData.Venue.MapLink,
        VenueDescription: formData.Venue.VenueDescription,
        VenueRegionId: formData.Venue.VenueRegionId, // Ensure this is included
      },
    };

    console.log("Submitting payload:", payload);

    const token = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")).token
      : null;

    if (!token) {
      setToast({
        message: "Access token is missing. Please log in.",
        type: "error",
      });
      return;
    }

    try {
      await updateEvent(event.EventId, payload, token);

      setToast({
        message: "Event updated successfully!",
        type: "success",
      });
    } catch (error) {
      console.error("Error updating event:", error);
      setToast({
        message: "Failed to update the event. Please try again.",
        type: "error",
      });
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white p-4 md:p-6 rounded-lg shadow-lg w-11/12 max-w-2xl">
        {/* Close Button */}
        <button
          type="button"
          onClick={onCancel}
          className="absolute top-3 right-3 text-gray-600 hover:text-red-500 text-2xl"
        >
          <FaTimes />
        </button>

        <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center">
          Edit Event
        </h2>
        {toast && (
          <ToastNotification
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}

        <div className="overflow-y-auto max-h-[70vh] pr-2">
          <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
            {/* Event Title */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Event Title *
              </label>
              <input
                type="text"
                name="EventTitle"
                value={formData.EventTitle}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            {/* Event Description */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Event Description *
              </label>
              <textarea
                name="EventDescription"
                value={formData.EventDescription}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            {/* Event Images */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Event Images
              </label>
              {formData.EventImages.length > 0 ? (
                formData.EventImages.map((image, index) => (
                  <div key={index} className="flex items-center mt-2">
                    {/* Dynamically show image preview */}
                    <img
                      src={image.preview || ""}
                      alt={`Event image ${index + 1}`}
                      className="w-20 h-20 object-cover rounded-lg mr-2"
                    />
                    <span>{image.Tags.join(", ")}</span>
                    <button
                      type="button"
                      className="ml-4 text-red-500"
                      onClick={() =>
                        setFormData((prevData) => ({
                          ...prevData,
                          EventImages: prevData.EventImages.filter(
                            (_, i) => i !== index
                          ),
                          EventImageFileId: prevData.EventImageFileId.filter(
                            (id) => id !== image.FileId
                          ),
                        }))
                      }
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p>No images uploaded</p>
              )}
              <button
                type="button"
                onClick={() => setShowCropper(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
              >
                Add New Image
              </button>
              {showCropper && (
                <CropperEventImage
                  onCropComplete={handleImageCrop}
                  onClose={() => setShowCropper(false)}
                />
              )}
            </div>

            {/* Event Type */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Event Type
              </label>
              <select
                name="EventType"
                value={formData.EventType}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="League">League</option>
                <option value="Tournament">Tournament</option>
                <option value="Bootcamp">Bootcamp</option>
              </select>
            </div>

            {/* Sport */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">Sport</label>
              <select
                name="SportId"
                value={formData.SportId || ""}
                onChange={handleInputChange}
                className="w-full p-3 border rounded"
                required
              >
                <option value="">Select Sport</option>
                {sports.map((sport) => (
                  <option key={sport.SportId} value={sport.SportId}>
                    {sport.SportType}
                  </option>
                ))}
              </select>
            </div>

            {/* Team Size */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Team Size
              </label>
              <input
                type="number"
                name="TeamSize"
                value={formData.TeamSize}
                onChange={handleInputChange}
                min="1"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Team Limit */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Total Teams
              </label>
              <input
                type="number"
                name="Total Teams"
                value={formData.TotalTeams}
                onChange={handleInputChange}
                min="1"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Price */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Price (INR)
              </label>
              <input
                type="number"
                name="Price"
                value={formData.Price || "0"}
                onChange={handleInputChange}
                className="w-full p-3 border rounded"
                min="0"
              />
            </div>

            {/* Price Credits */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Price Credits
              </label>
              <input
                type="number"
                name="PriceCredits"
                value={formData.PriceCredits || "0"}
                onChange={handleInputChange}
                className="w-full p-3 border rounded"
                min="0"
              />
            </div>

            {/* Venue */}
            <div>
              <label className="block text-lg font-semibold mb-1">
                Venue *
              </label>
              <select
                name="VenueId"
                value={formData.Venue.VenueId}
                onChange={handleVenueChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              >
                <option value="">Select a Venue</option>
                {venues.map((venue) => (
                  <option key={venue.VenueId} value={venue.VenueId}>
                    {venue.VenueName}
                  </option>
                ))}
              </select>
            </div>

            {/* Sponsors */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Sponsors
              </label>
              <div className="flex flex-wrap gap-4">
                {sponsors.map((sponsor) => (
                  <div
                    key={sponsor.SponsorId}
                    className="flex items-center gap-2"
                  >
                    <input
                      type="checkbox"
                      id={`sponsor-${sponsor.SponsorId}`}
                      name="SponsorIds"
                      value={sponsor.SponsorId}
                      checked={formData.SponsorIds.includes(sponsor.SponsorId)} // Check against formData
                      onChange={handleSponsorChange}
                      className="h-5 w-5"
                    />
                    <label
                      htmlFor={`sponsor-${sponsor.SponsorId}`}
                      className="text-gray-700"
                    >
                      {sponsor.SponsorName}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* Event Start Date & Time */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Event Start Date & Time *
              </label>
              <div className="flex gap-4">
                {(() => {
                  const { formattedDate, formattedTime } = formatDateTime(
                    formData.EventSchedule.StartDate
                  );
                  return (
                    <>
                      <input
                        type="date"
                        name="StartDate"
                        value={formattedDate}
                        onChange={(e) =>
                          handleEventDateChange(
                            e,
                            "StartDate",
                            "StartTimeStamp"
                          )
                        }
                        className="w-1/2 p-3 border border-gray-300 rounded-lg"
                        required
                      />
                      <input
                        type="time"
                        name="StartTimeStamp"
                        value={formattedTime}
                        onChange={(e) =>
                          handleEventTimeChange(
                            e,
                            "StartTimeStamp",
                            "StartDate"
                          )
                        }
                        className="w-1/2 p-3 border border-gray-300 rounded-lg"
                        required
                      />
                    </>
                  );
                })()}
              </div>
            </div>

            {/* Event End Date & Time */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Event End Date & Time *
              </label>
              <div className="flex gap-4">
                {/* End Date Input */}
                <input
                  type="date"
                  name="EndDate"
                  value={formData.EventSchedule.EndDate.slice(0, 10)}
                  onChange={(e) =>
                    handleEventDateChange(e, "EndDate", "EndTimeStamp")
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
                {/* End Time Input */}
                <input
                  type="time"
                  name="EndTimeStamp"
                  value={formData.EventSchedule.EndTimeStamp.slice(11, 16)}
                  onChange={(e) =>
                    handleEventTimeChange(e, "EndTimeStamp", "EndDate")
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>

            {/* Registration Start Date & Time */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Registration Start Date & Time *
              </label>
              <div className="flex gap-4">
                {/* Start Date Input */}
                <input
                  type="date"
                  name="RegistrationStartDate"
                  value={formData.EventSchedule.RegistrationStartDate.slice(
                    0,
                    10
                  )}
                  onChange={(e) =>
                    handleRegistrationDateChange(
                      e,
                      "RegistrationStartDate",
                      "RegistrationStartTimeStamp"
                    )
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
                {/* Start Time Input */}
                <input
                  type="time"
                  name="RegistrationStartTimeStamp"
                  value={formData.EventSchedule.RegistrationStartTimeStamp.slice(
                    11,
                    16
                  )}
                  onChange={(e) =>
                    handleRegistrationTimeChange(
                      e,
                      "RegistrationStartTimeStamp",
                      "RegistrationStartDate"
                    )
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>

            {/* Registration End Date & Time */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Registration End Date & Time *
              </label>
              <div className="flex gap-4">
                {/* End Date Input */}
                <input
                  type="date"
                  name="RegistrationEndDate"
                  value={formData.EventSchedule.RegistrationEndDate.slice(
                    0,
                    10
                  )}
                  onChange={(e) =>
                    handleRegistrationDateChange(
                      e,
                      "RegistrationEndDate",
                      "RegistrationEndTimeStamp"
                    )
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
                {/* End Time Input */}
                <input
                  type="time"
                  name="RegistrationEndTimeStamp"
                  value={formData.EventSchedule.RegistrationEndTimeStamp.slice(
                    11,
                    16
                  )}
                  onChange={(e) =>
                    handleRegistrationTimeChange(
                      e,
                      "RegistrationEndTimeStamp",
                      "RegistrationEndDate"
                    )
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>

            {/* Recurrence Details */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Is Recurring Event?
              </label>
              <input
                type="checkbox"
                name="IsRecurring"
                checked={formData.EventSchedule.IsRecurring}
                onChange={handleIsRecurringChange} // Call the handler here
                className="mr-2"
              />
            </div>

            {/* Recurrence Rule */}
            {formData.EventSchedule.IsRecurring && (
              <>
                <div className="mb-6">
                  <label className="block text-lg font-semibold mb-2">
                    Recurrence Rule
                  </label>
                  <select
                    name="RecurrenceRule"
                    value={formData.EventSchedule.RecurrenceRule} // Bind to state
                    onChange={handleRecurrenceRuleChange} // Update state on change
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  >
                    <option value="">Select Recurrence Rule</option>{" "}
                    {/* Default placeholder */}
                    <option value="DAILY">Daily</option>
                    <option value="WEEKLY">Weekly</option>
                    <option value="MONTHLY">Monthly</option>
                  </select>
                </div>

                {/* Recurrence Interval */}
                <div className="mb-6">
                  <label className="block text-lg font-semibold mb-2">
                    Recurrence Interval (Number of Days/Weeks/Months)
                  </label>
                  <input
                    type="number"
                    name="RecurrenceInterval"
                    value={formData.EventSchedule.RecurrenceInterval}
                    onChange={handleRecurrenceIntervalChange} // Call the handler here
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    min="1"
                  />
                </div>
                {/* Recurrence End Date & Time */}
                <div className="mb-6">
                  <label className="block text-lg font-semibold mb-2">
                    Recurrence End Date & Time
                  </label>
                  <div className="flex gap-4">
                    {/* Recurrence End Date Input */}
                    <input
                      type="date"
                      name="RecurrenceEndDate"
                      value={formData.EventSchedule.RecurrenceEndDate.slice(
                        0,
                        10
                      )}
                      onChange={handleRecurrenceEndDateChange}
                      className="w-1/2 p-3 border border-gray-300 rounded-lg"
                    />

                    {/* Recurrence End Time Input */}
                    <input
                      type="time"
                      name="RecurrenceEndTime"
                      value={
                        formData.EventSchedule.RecurrenceEndDate
                          ? new Date(formData.EventSchedule.RecurrenceEndDate)
                              .toLocaleTimeString("en-US", { hour12: false })
                              .slice(0, 5)
                          : "00:00"
                      }
                      onChange={handleRecurrenceEndTimeChange}
                      className="w-1/2 p-3 border border-gray-300 rounded-lg"
                    />
                  </div>
                </div>

                {/* Recurrence Days of the Week */}
                {formData.EventSchedule.RecurrenceRule === "WEEKLY" && (
                  <div className="mb-6">
                    <label className="block text-lg font-semibold mb-2">
                      Recurrence Days of the Week
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      {[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ].map((day) => (
                        <div key={day} className="flex items-center">
                          <input
                            type="checkbox"
                            id={day}
                            name="RecurrenceDaysOfWeek"
                            value={day}
                            checked={formData.EventSchedule.RecurrenceDaysOfWeek.includes(
                              day
                            )}
                            onChange={handleDaysOfWeekChange} // Call the handler here
                            className="mr-2"
                          />
                          <label htmlFor={day} className="text-gray-700">
                            {day}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">FAQs</label>
              {formData.FAQ.map((faq, index) => (
                <div key={index} className="mb-4">
                  <input
                    type="text"
                    name="question"
                    placeholder="Question"
                    value={faq.question}
                    onChange={(e) => handleFAQChange(index, e)}
                    className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                  />
                  <textarea
                    name="answer"
                    placeholder="Answer"
                    value={faq.answer}
                    onChange={(e) => handleFAQChange(index, e)}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={addFAQ}
                className="bg-blue-500 text-white p-2 rounded-lg"
              >
                Add FAQ
              </button>
            </div>

            {/* Submit Button */}
            <div className="text-right">
              <button
                type="submit"
                className="bg-blue-500 text-white p-2 rounded-lg"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditEventForm;
