import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchCoachingEvents, deleteCoachingEvent } from "../api/api";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import Loader from "../components/Loader";

const CoachingList = () => {
  const [coachingEvents, setCoachingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadCoachingEvents = async () => {
      try {
        const data = await fetchCoachingEvents("asc", 10, 0);
        setCoachingEvents(data.events);
      } catch (error) {
        setError("Failed to load coaching events");
      } finally {
        setLoading(false);
      }
    };

    loadCoachingEvents();
  }, []);

  const handleDelete = async (coachEventId) => {
    if (
      window.confirm("Are you sure you want to delete this coaching event?")
    ) {
      try {
        const token = localStorage.getItem("auth")
          ? JSON.parse(localStorage.getItem("auth")).token
          : null;
        if (!token) {
          alert("Access token is missing. Please log in.");
          return;
        }

        await deleteCoachingEvent(coachEventId, token);
        setCoachingEvents((prev) =>
          prev.filter((event) => event.CoachEventId !== coachEventId)
        );
      } catch (error) {
        alert("Failed to delete coaching event.");
      }
    }
  };

  if (loading) return <Loader />;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Coaching Events</h1>
        <Link
          to="/coaching/create"
          className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-3 rounded-lg shadow-lg hover:bg-indigo-700 flex items-center justify-center sm:w-auto w-12 h-12"
        >
          <FaPlus size={20} className="sm:hidden" />
          <span className="hidden sm:inline-block ml-2 text-base sm:text-lg">
            Create Event
          </span>
        </Link>
      </div>

      {coachingEvents.length === 0 ? (
        <p className="text-gray-700 text-center text-lg">
          No coaching events available. Please create a new coaching event.
        </p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {coachingEvents.map((event) => (
            <div
              key={event.CoachEventId}
              className="bg-white p-4 rounded shadow"
            >
              <h2 className="text-xl font-semibold">{event.EventTitle}</h2>
              <p className="text-gray-700 mb-2">{event.EventDescription}</p>
              <div className="flex justify-between items-center">
                <Link
                  to={`/coaching/details/${event.CoachEventId}`}
                  className="text-blue-500 hover:underline"
                >
                  View Details
                </Link>
                <button
                  onClick={() => handleDelete(event.CoachEventId)}
                  className="text-red-500 hover:text-red-700"
                  title="Delete Event"
                >
                  <FaTrashAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CoachingList;
