import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../api/api";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState("All"); // New state for user type filter
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = await getUsers(100, 0);
        const usersData = data?.users || [];

        setUsers(usersData);
        setFilteredUsers(usersData);
      } catch (err) {
        setError("Failed to fetch users.");
        console.error("Error fetching users:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleUserClick = (userId) => {
    navigate(`/master/user-master/${userId}`);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    applyFilters(value, selectedRole);
  };

  const handleRoleFilter = (e) => {
    const role = e.target.value;
    setSelectedRole(role);
    applyFilters(searchTerm, role);
  };

  const applyFilters = (search, role) => {
    let filtered = users;

    if (role !== "All") {
      filtered = filtered.filter((user) => user.RoleType === role);
    }

    if (search) {
      filtered = filtered.filter(
        (user) =>
          `${user.FirstName} ${user.LastName}`.toLowerCase().includes(search) ||
          user.UserId.toLowerCase().includes(search) ||
          user.MobileNumber?.toLowerCase().includes(search)
      );
    }

    setFilteredUsers(filtered);
  };

  if (loading) return <p className="text-gray-500">Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">User List</h2>

      {/* Search and Filter Inputs */}
      <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by Name, User ID, or Mobile Number"
          className="w-full md:w-2/3 p-3 border border-gray-300 rounded-lg"
        />

        <select
          value={selectedRole}
          onChange={handleRoleFilter}
          className="w-full md:w-1/3 p-3 border border-gray-300 rounded-lg"
        >
          <option value="All">All Users</option>
          <option value="General">General</option>
          <option value="Coach">Coach</option>
          <option value="Admin">Admin</option>
        </select>
      </div>

      {/* User Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">#</th>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">User ID</th>
              <th className="border border-gray-300 px-4 py-2">Mobile Number</th>
              <th className="border border-gray-300 px-4 py-2">Role</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr
                key={user.UserId}
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => handleUserClick(user.UserId)}
              >
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {index + 1}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.FirstName} {user.LastName}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.UserId}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.MobileNumber || "N/A"}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.RoleType}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {filteredUsers.length === 0 && (
        <p className="text-gray-500 mt-4">No users found.</p>
      )}
    </div>
  );
};

export default UserList;
