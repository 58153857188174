import React, { useRef, useState, useCallback, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CropperEventImage = ({ onCropComplete, onClose, usedCropOptions }) => {
  const [crop, setCrop] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [notification, setNotification] = useState(null);
  const imgRef = useRef(null);


    // Crop options with aspect ratios and minimum dimensions
    const cropOptions = {
      SPOTLIGHT: { aspect: 309 / 346, minWidth: 309, minHeight: 346 },
      TALL: { aspect: 209 / 309, minWidth: 209, minHeight: 309 },
      WIDE: { aspect: 430 / 310, minWidth: 430, minHeight: 310 },
      SQUARE: { aspect: 1, minWidth: 310, minHeight: 310 },
    };
  

  // Determine the first available crop option
  const getDefaultCropOption = () => {
    const availableOptions = Object.keys(cropOptions).filter(
      (option) => !usedCropOptions.includes(option)
    );
    return availableOptions[0] || null;
  };


  const [selectedOption, setSelectedOption] = useState(getDefaultCropOption());


  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle cropping option change
  const handleOptionChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);

    const { aspect, minWidth } = cropOptions[option];
    setCrop({
      unit: "px",
      width: minWidth,
      height: minWidth / aspect,
      x: 0,
      y: 0,
    });
  };

  // Generate cropped blob
  const generateCroppedBlob = useCallback(() => {
    if (!crop || !imgRef.current) return;

    const canvas = document.createElement("canvas");
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    const imageType = imgRef.current.src.startsWith("data:image/png")
      ? "image/png"
      : "image/jpeg";

    canvas.toBlob((blob) => {
      if (blob) {
        setCroppedBlob(blob); // Store blob in state
      }
    }, imageType);
  }, [crop]);

  useEffect(() => {
    generateCroppedBlob();
  }, [generateCroppedBlob]);

  // Validate image dimensions against crop requirements
  const validateImageSize = (image) => {
    const { minWidth, minHeight } = cropOptions[selectedOption];
    if (image.naturalWidth < minWidth || image.naturalHeight < minHeight) {
      setNotification(
        `Image is too small. Minimum size for ${selectedOption} is ${minWidth}x${minHeight}.`
      );
      return false;
    }
    setNotification(null);
    return true;
  };

  // Handle image load
  const handleImageLoad = () => {
    const image = imgRef.current;
    if (!validateImageSize(image)) {
      setImageUrl(null);
    }
  };

  // Handle upload button click
  const handleUpload = () => {
    if (croppedBlob) {
      onCropComplete(croppedBlob, selectedOption);
    }
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg max-w-4xl w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
        >
          ✕
        </button>
        {!imageUrl && (
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="mb-4 w-full"
          />
        )}
        {notification && (
          <p className="text-red-500 mb-4 text-center">{notification}</p>
        )}
        {imageUrl && (
          <>
            <div className="mb-4">
              <label className="block mb-2">Select Crop Type</label>
              <select
                onChange={handleOptionChange}
                value={selectedOption}
                className="border border-gray-300 rounded p-2"
              >
                {Object.entries(cropOptions).map(
                  ([key, { minWidth, minHeight }]) => (
                    <option
                      key={key}
                      value={key}
                      disabled={usedCropOptions.includes(key)}
                    >
                      {`${key} (${minHeight}x${minWidth})`}
                    </option>
                  )
                )}
              </select>
              {Object.keys(cropOptions).every((option) =>
                usedCropOptions.includes(option)
              ) && (
                <p className="text-green-500 mt-2">
                  All crop options have been used. No other image required.
                </p>
              )}
            </div>

            <div className="flex justify-center overflow-auto max-h-96">
              <ReactCrop
                src={imageUrl}
                crop={crop}
                onChange={(newCrop) => {
                  const { minWidth, aspect } = cropOptions[selectedOption];
                  const newWidth = Math.max(newCrop.width, minWidth);
                  const newHeight = newWidth / aspect;

                  setCrop({
                    ...newCrop,
                    width: newWidth,
                    height: newHeight,
                  });
                }}
                onComplete={(c) => setCrop(c)}
                aspect={cropOptions[selectedOption].aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop Preview"
                  src={imageUrl}
                  onLoad={handleImageLoad}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "500px",
                  }}
                />
              </ReactCrop>
            </div>
            {croppedBlob && (
              <button
                onClick={handleUpload}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
              >
                Upload
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CropperEventImage;
